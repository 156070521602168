import { toast } from "react-toastify";
import { toastOptions } from "../../services/toast-options";

//mui
import { Tooltip } from "@mui/material";
import { useConfirm } from "material-ui-confirm";

// styled-components
import { IconButtonCss, DeleteIconCss } from "./DeleteButtonDialog.styled";

export const DeleteButtonDialog = ({ images, id, onRemove }) => {
  const confirm = useConfirm();

  const onRemoveItem = (id) => {
    onRemove(images.filter((image) => image.id !== id));
    toast.success(`Image deleted`, toastOptions);
    console.log("Image deleted");
  };

  const onCanceledRemoveItem = () => {
    toast.warning(`Deletion cancelled`, toastOptions);
    console.log("Deletion cancelled.");
  };

  const handleDelete = () => {
    confirm({ description: `This will permanently delete the image.` })
      .then(() => onRemoveItem(id))
      .catch(() => onCanceledRemoveItem());
  };

  return (
    <IconButtonCss onClick={() => handleDelete()}>
      <Tooltip title="Delete" placement="left">
        <DeleteIconCss color="primary" />
      </Tooltip>
    </IconButtonCss>
  );
};
