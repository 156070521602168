// outer sources
import { IconButton } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import ReactGA from "react-ga4";

// styled-components
import { PrintPanelBoxCss } from "./PrintPanel.styled";

export const PrintPanel = () => {
  const handleOnClickSaveBtn = () => {
    ReactGA.event({category: "User", action: "Print Report"});
    window.print();
  };

  return (
    <PrintPanelBoxCss>
      <IconButton
        color="primary"
        size="large"
        onClick={() => {
          handleOnClickSaveBtn();
        }}
      >
        <PrintIcon fontSize="large" />
      </IconButton>
    </PrintPanelBoxCss>
  );
};
