// components
import { ImgNumberShow } from "components/ImgNumberShow";
import { ImgNumberPosition } from "components/ImgNumberPosition";

// mui
import { ButtonGroup } from "@mui/material";

// styled-components
import { ImgNumberControlsBoxCss } from "./ImgNumberControls.styled";

export const ImgNumberControls = () => {
  return (
    <ImgNumberControlsBoxCss>
      <ButtonGroup
        variant="contained"
        type={"button"}
        aria-label="Image number controls button group"
      >
        <ImgNumberPosition />

        <ImgNumberShow />
      </ButtonGroup>
    </ImgNumberControlsBoxCss>
  );
};
