// components
import { ImgNumberControls } from "components/ImgNumberControls/ImgNumberControls";

// styled-components
import { LayoutDesignPanelBoxCss } from "./LayoutDesignPanel.styled";

export const LayoutDesignPanel = () => {
  return (
    <LayoutDesignPanelBoxCss>
      <ImgNumberControls />
    </LayoutDesignPanelBoxCss>
  );
};
