import { useState } from "react";
import Cropper from "react-easy-crop";
import { toast } from "react-toastify";
import { toastOptions } from "../../services/toast-options";

// components
import { Button } from "components/Button";

// utils
import { getCroppedImg } from "utils/canvasUtils";

// mui
import { Tooltip, Modal, IconButton, Slider } from "@mui/material";
import CropIcon from "@mui/icons-material/Crop";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";

// styled-components
import {
  PhotoCropWrapperCss,
  InnerModalBoxCss,
  CropContainerCss,
  ControlsContainerCss,
  SliderBoxCss,
  SliderTitleCss,
  ButtonBoxCss,
  AspectTemplatesBtnGroup,
} from "./PhotoCrop.styled";

export const PhotoCrop = ({ image, images, onUpdate }) => {
  const [aspectRatio, setAspectRatio] = useState(16 / 9);
  const [showModal, setShowModal] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  // Show modal if edit button press
  const showModalHandler = () => {
    if (image) {
      setShowModal(true);
    }
  };

  // Hide modal
  const hideModal = () => {
    setShowModal(false);
  };

  const onCropComplete = (_croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const onClickSaveCroppedImage = async () => {
    try {
      const croppedImage = await getCroppedImg(
        image.preview ? image.preview : image.path,
        croppedAreaPixels,
        rotation
      );

      images.forEach((item, index) => {
        if (item.id === image.id) {
          let newItem = new File([croppedImage], image.name, {
            type: image.type,
          });

          newItem = Object.assign(newItem, {
            id: image.id,
            folderId: image.folderId,
            preview: URL.createObjectURL(croppedImage),
            path: image.path,
          });

          setShowModal(false);
          toast.success(`Cropping is saved`, toastOptions);
          images[index] = newItem;
        }
      });

      onUpdate(images);
    } catch (e) {
      console.error(e);
    }
  };

  const getAspectFromTemplatesPanel = (value) => {
    setAspectRatio(value);
  };

  const onClickAspectTemplateBtn = (e) => {
    getAspectFromTemplatesPanel(e.currentTarget.value);
  };

  return (
    <PhotoCropWrapperCss>
      {image && (
        <Tooltip title="Crop" placement="left">
          <IconButton size="medium" onClick={() => showModalHandler()}>
            <CropIcon color="primary" fontSize="medium" />
          </IconButton>
        </Tooltip>
      )}

      {showModal ? (
        <Modal open={showModal} onClose={hideModal}>
          <InnerModalBoxCss>
            <CropContainerCss>
              <Cropper
                image={image.preview ? image.preview : image.path}
                crop={crop}
                zoom={zoom}
                rotation={rotation}
                aspect={aspectRatio}
                onCropChange={setCrop}
                onRotationChange={setRotation}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            </CropContainerCss>

            <ControlsContainerCss>
              <AspectTemplatesBtnGroup
                variant="contained"
                type={"button"}
                aria-label="Aspect templates button group"
              >
                <Button
                  name={"16 / 9"}
                  value={16 / 9}
                  startIcon={<CropIcon />}
                  onClick={(e) => {
                    onClickAspectTemplateBtn(e);
                  }}
                />
                <Button
                  name={"4 / 3"}
                  value={4 / 3}
                  startIcon={<CropIcon />}
                  onClick={(e) => {
                    onClickAspectTemplateBtn(e);
                  }}
                />
                <Button
                  name={"3 / 2"}
                  value={3 / 2}
                  startIcon={<CropIcon />}
                  onClick={(e) => {
                    onClickAspectTemplateBtn(e);
                  }}
                />
                <Button
                  name={"5 / 4"}
                  value={5 / 4}
                  startIcon={<CropIcon />}
                  onClick={(e) => {
                    onClickAspectTemplateBtn(e);
                  }}
                />
                <Button
                  name={"7 / 5"}
                  value={7 / 5}
                  startIcon={<CropIcon />}
                  onClick={(e) => {
                    onClickAspectTemplateBtn(e);
                  }}
                />
                <Button
                  name={"1 / 1"}
                  value={1 / 1}
                  startIcon={<CropIcon />}
                  onClick={(e) => {
                    onClickAspectTemplateBtn(e);
                  }}
                />
              </AspectTemplatesBtnGroup>

              <AspectTemplatesBtnGroup
                variant="contained"
                type={"button"}
                aria-label="Aspect templates button group"
              >
                <Button
                  name={"9 / 16"}
                  value={9 / 16}
                  startIcon={<CropIcon />}
                  onClick={(e) => {
                    onClickAspectTemplateBtn(e);
                  }}
                />
                <Button
                  name={"3 / 4"}
                  value={3 / 4}
                  startIcon={<CropIcon />}
                  onClick={(e) => {
                    onClickAspectTemplateBtn(e);
                  }}
                />
                <Button
                  name={"2 / 3"}
                  value={2 / 3}
                  startIcon={<CropIcon />}
                  onClick={(e) => {
                    onClickAspectTemplateBtn(e);
                  }}
                />
                <Button
                  name={"4 / 5"}
                  value={4 / 5}
                  startIcon={<CropIcon />}
                  onClick={(e) => {
                    onClickAspectTemplateBtn(e);
                  }}
                />
                <Button
                  name={"5 / 7"}
                  value={5 / 7}
                  startIcon={<CropIcon />}
                  onClick={(e) => {
                    onClickAspectTemplateBtn(e);
                  }}
                />
              </AspectTemplatesBtnGroup>

              <SliderBoxCss>
                <SliderTitleCss>Zoom</SliderTitleCss>
                <Slider
                  type="range"
                  value={zoom}
                  min={1}
                  max={10}
                  step={0.1}
                  aria-labelledby="Zoom"
                  onChange={(e) => {
                    setZoom(e.target.value);
                  }}
                  aria-label="Small"
                  valueLabelDisplay="auto"
                />
              </SliderBoxCss>

              <SliderBoxCss>
                <SliderTitleCss>Rotation</SliderTitleCss>
                <Slider
                  value={rotation}
                  min={0}
                  max={360}
                  step={1}
                  aria-labelledby="Rotation"
                  onChange={(e, rotation) => setRotation(rotation)}
                  aria-label="Small"
                  valueLabelDisplay="auto"
                />
              </SliderBoxCss>

              <ButtonBoxCss>
                <Button
                  type={"button"}
                  name={"Close"}
                  startIcon={<CloseIcon />}
                  onClick={() => {
                    setShowModal(false);
                  }}
                />

                <Button
                  type={"button"}
                  name={"Save"}
                  startIcon={<SaveIcon />}
                  onClick={() => {
                    onClickSaveCroppedImage();
                  }}
                />
              </ButtonBoxCss>
            </ControlsContainerCss>
          </InnerModalBoxCss>
        </Modal>
      ) : null}
    </PhotoCropWrapperCss>
  );
};
