import { ToastContainer } from "react-toastify";
import ReactGA from "react-ga4";

// styles
import "../App.css";
import { PageRouter } from "../router/PageRouter";

export const App = () => {
  if (process.env.REACT_APP_GA_ID) {
    ReactGA.initialize(process.env.REACT_APP_GA_ID);
  }
  
  return (
    <div className="App">
      <PageRouter />

      <ToastContainer />
    </div>
  );
};
