// outer sources
import React, { useEffect, useState } from "react";
import { ReactPhotoEditor } from "react-photo-editor";
import "react-photo-editor/dist/style.css";
import { toast } from "react-toastify";

// services
import { toastOptions } from "services/toast-options";

// mui
import { Tooltip, Modal, Box, IconButton } from "@mui/material";
import TuneIcon from "@mui/icons-material/Tune";

// styled-components
import { PhotoEditorWrapperCss } from "./PhotoEditor.styled";

export const PhotoEditor = ({ image, images, onUpdate }) => {
  const [showModal, setShowModal] = useState(false);
  const [file, setFile] = useState(false);

  useEffect(() => {
    async function createFileFromIage(image) {
      if (image instanceof File) {
        setFile(image);
        return;
      }

      const response = await fetch(image.path);
      const blob = await response.blob();
      const file = new File([blob], image.path.split("/").pop(), {
        type: blob.type,
      });
      setFile(file);
    }
    createFileFromIage(image);
  }, [image]);

  // Show modal if edit button press
  const showModalHandler = () => {
    if (file) {
      setShowModal(true);
    }
  };

  // Hide modal
  const hideModal = () => {
    setShowModal(false);
  };

  // Save edited image
  const handleSaveImage = (editedFile) => {
    images.forEach((item, index) => {
      if (item.id === editedFile.id) {
        editedFile = Object.assign(editedFile, {
          preview: URL.createObjectURL(editedFile),
          path: item.path,
        });

        setShowModal(false);
        toast.success(`Edit saved`, toastOptions);
        images[index] = editedFile;
      }
    });
    onUpdate(images);
  };

  return (
    <PhotoEditorWrapperCss>
      {image && (
        <Tooltip title="Tune" placement="left">
          <IconButton size="medium" onClick={() => showModalHandler()}>
            <TuneIcon color="primary" fontSize="medium" />
          </IconButton>
        </Tooltip>
      )}

      {showModal ? (
        <Modal open={showModal} onClose={hideModal}>
          <Box>
            <ReactPhotoEditor
              open={showModal}
              onClose={hideModal}
              file={file}
              allowColorEditing={true}
              allowFlip={true}
              allowZoom={true}
              allowRotate={true}
              onSaveImage={(editedImage) => {
                editedImage = Object.assign(editedImage, {
                  id: image.id,
                  folderId: image.folderId,
                });
                handleSaveImage(editedImage);
              }}
              downloadOnSave={false}
            />
          </Box>
        </Modal>
      ) : null}
    </PhotoEditorWrapperCss>
  );
};
