import { EditTextarea } from "react-edit-text";

// styled-components
import {
  EditTextAreaWrapperCss,
  EditNoteIconCss,
} from "./EditTextAreaField.styled";

const generateIconForEditTextArea = () => {
  return <EditNoteIconCss className={"EditNoteIconCss"} color="primary" />;
};

export const EditTextAreaField = ({ defaultText, style, addIcon }) => {
  return (
    <EditTextAreaWrapperCss>
      {addIcon && defaultText.length > 0 ? generateIconForEditTextArea() : null}
      <EditTextarea rows={"auto"} defaultValue={defaultText} style={style} />
    </EditTextAreaWrapperCss>
  );
};
