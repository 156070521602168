// outer sources
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// services
import { acceptInvite } from "services/apiService";
import { toastOptions } from "services/toast-options";

// mui
import { Typography, Button } from "@mui/material";

// styled components
import {
  AcceptInviteContainerCss,
  InvitationCardCss,
  InvitationCardContentCss,
  InvitationCardActionsCss,
} from "./AcceptInvite.styled";

export const AcceptInvite = () => {
  const navigate = useNavigate();
  const [organizationName, setOrganizationName] = useState("");

  useEffect(() => {
    try {
      const token = localStorage.getItem("invitation_token");
      const decoded = jwtDecode(token);
      // TODO: Add expiration validation
      setOrganizationName(decoded.organizationName);
    } catch (error) {
      navigate("/");
    }
  }, [navigate]);

  const handleAccept = async () => {
    try {
      const result = await acceptInvite();
      if (result.error === undefined) {
        toast.success("Invitation was accepted.", toastOptions);
        handleDecline();
      }
    } catch (error) {
      toast.error(
        "Something went wrong. Please try again later.",
        toastOptions
      );
    }
  };

  const handleDecline = () => {
    localStorage.removeItem("invitation_token");
    navigate("/");
  };

  return (
    <HelmetProvider>
      <Helmet>
        <title>Appraisal Photo Report | Accept Invitation</title>
      </Helmet>

      <AcceptInviteContainerCss>
        <InvitationCardCss>
          <InvitationCardContentCss>
            <Typography variant="h5">Administrator of</Typography>

            <Typography
              variant="h3"
              component="div"
              sx={{ fontWeight: "bold" }}
            >
              {organizationName || "Organization Name"}
            </Typography>

            <Typography variant="h5">
              invites you to join the organization
            </Typography>

            <Typography variant="h5">in the APR application</Typography>
          </InvitationCardContentCss>

          <InvitationCardActionsCss>
            <Button
              type="button"
              variant="contained"
              fullWidth
              color="primary"
              onClick={handleAccept}
            >
              Accept
            </Button>
            <Button
              type="button"
              variant="outlined"
              fullWidth
              color="error"
              onClick={handleDecline}
            >
              Decline
            </Button>
          </InvitationCardActionsCss>
        </InvitationCardCss>
      </AcceptInviteContainerCss>
    </HelmetProvider>
  );
};
