import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const ImgWrapperCss = styled(Box)`
  position: relative;

  & .image-settings {
    opacity: 0;
    transition: visibility 0ms, opacity 500ms linear;
  }

  &:hover .image-settings {
    visibility: visible;
    opacity: 1;
  }
`;

export const ImgCss = styled.img`
  width: 480px;
  height: auto;
  border: 0.1px solid grey;
`;

export const ImgNumberBoxCSS = styled(Box)`
  &.img-number {
    position: absolute;
    left: 5px;
    bottom: 5px;
    min-width: 30px;
    padding: 3px 8px;

    border-radius: 2px;
    border: 0.1px solid grey;
    background-color: rgba(255, 255, 255, 0.99);

    font-size: 12px;
    color: black;
    font-weight: bold;

    &.img-number-hide {
      display: none;
    }

    &.img-number-left {
      left: 5px;
      right: auto;
    }

    &.img-number-center {
      left: 45%;
    }

    &.img-number-right {
      right: 5px;
      left: auto;
    }
  }
`;

export const FolderNotesWrapperCss = styled(Box)`
  width: 100%;
`;
