import styled from "@emotion/styled";
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
  IconButton,
} from "@mui/material";

import SettingsIcon from "@mui/icons-material/Settings";

export const WrapperAccordionCss = styled(Box)`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 50px;

  & .MuiAccordionSummary-content {
    display: none;
  }
`;

export const AccordionCss = styled(Accordion)``;

export const AccordionSummaryCss = styled(AccordionSummary)``;

export const AccordionDetailsCss = styled(AccordionDetails)`
  padding: 3px;
`;

export const TooltipCss = styled(Tooltip)``;

export const IconButtonCss = styled(IconButton)``;

export const SettingsIconCss = styled(SettingsIcon)``;
