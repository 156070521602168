// outer sources
import Grid from "@mui/material/Grid2";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";

// utils
import { reorderArray } from "utils/reorderArray";

// mui
import {
  Tooltip,
  List,
  ListItem,
  ListItemButton,
  IconButton,
  ListItemText,
} from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";

export const TocList = ({ folders, onUpdate, handleDrawerClose }) => {
  const handleDragEnd = ({ source, destination }, _provided) => {
    if (!destination) {
      return;
    }
    onUpdate(reorderArray(folders, source.index, destination.index));
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="droppable">
        {(droppableProvided, snapshot) => (
          <Grid
            container
            spacing={0}
            columns={1}
            direction={"column"}
            style={{
              backgroundColor: snapshot.isDraggingOver ? "lightGrey" : "white",
            }}
            ref={droppableProvided.innerRef}
            {...droppableProvided.droppableProps}
          >
            {droppableProvided.placeholder}

            {folders.map((folder, index) => {
              const folderName = folder.name;
              const images = folder.folder_files;

              return (
                <Draggable
                  key={folderName}
                  draggableId={folderName}
                  index={index}
                >
                  {(draggableProvided) => (
                    <Grid
                      ref={draggableProvided.innerRef}
                      size={"auto"}
                      {...draggableProvided.draggableProps}
                      {...draggableProvided.dragHandleProps}
                    >
                      <List
                        sx={{
                          display: "flex",
                          maxWidth: 400,
                          bgcolor: "background.paper",
                          padding: "1px 0",
                        }}
                        aria-label="folders"
                      >
                        <ListItem disablePadding>
                          <ListItemButton sx={{ padding: "0 10px" }}>
                            <ListItemText
                              primary={`${index + 1}. ${folderName}`}
                              secondary={`Number of images: ${images.length}`}
                            />
                          </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding sx={{ width: "15%" }}>
                          <Tooltip title="Go to this folder" placement="top">
                            <IconButton
                              component="a"
                              color="primary"
                              size="large"
                              href={`#${index + 1}`}
                              onClick={() => handleDrawerClose(false)}
                            >
                              <LaunchIcon />
                            </IconButton>
                          </Tooltip>
                        </ListItem>
                      </List>
                    </Grid>
                  )}
                </Draggable>
              );
            })}
          </Grid>
        )}
      </Droppable>
    </DragDropContext>
  );
};
