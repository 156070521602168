import styled from "@emotion/styled";
import { Box } from "@mui/material";

// mui icons
import EditNoteIcon from "@mui/icons-material/EditNote";

export const EditTextWrapperCss = styled(Box)`
  position: relative;
  margin: 0 auto;
  max-width: 970px;
  text-align: justify;
`;

export const EditNoteIconCss = styled(EditNoteIcon)`
  position: absolute;
  top: -3px;
  left: -30px;
`;
