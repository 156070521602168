// outer sources
import Grid from "@mui/material/Grid2";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";

// components
import { EditTextAreaField } from "components/EditTextAreaField";
import { ImageSettingsPanel } from "components/ImageSettingsPanel";

// utils
import { reorderArray } from "utils/reorderArray";

// styled-components
import {
  ImgWrapperCss,
  ImgCss,
  ImgNumberBoxCSS,
  FolderNotesWrapperCss,
} from "./GridFolderImages.styled";

export const GridFolderImages = ({
  images,
  indexBasis,
  onUpdate,
}) => {
  const handleDragEnd = ({ source, destination }, _provided) => {
    if (!destination) {
      return;
    }
    onUpdate(reorderArray(images, source.index, destination.index));
  };

  const generateImagesDOM = (image, index) => {
    return (
      <Draggable key={image.id} draggableId={image.id} index={index}>
        {(draggableProvided) => (
          <Grid
            ref={draggableProvided.innerRef}
            size={"auto"}
            {...draggableProvided.draggableProps}
            {...draggableProvided.dragHandleProps}
          >
            <ImgWrapperCss>
              <ImgNumberBoxCSS className="img-number">
                {`Фото ${indexBasis + index + 1}`}
              </ImgNumberBoxCSS>

              <ImageSettingsPanel
                image={image}
                images={images}
                id={image.id}
                onUpdate={onUpdate}
              />

              <ImgCss src={image.preview ? image.preview : image.path} alt={image.name} />
            </ImgWrapperCss>
          </Grid>
        )}
      </Draggable>
    );
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="droppable">
        {(droppableProvided, snapshot) => (
          <Grid
            container
            spacing={1}
            columns={2}
            style={{
              backgroundColor: snapshot.isDraggingOver ? "#f7d0c3" : "white",
            }}
            ref={droppableProvided.innerRef}
            {...droppableProvided.droppableProps}
          >
            {droppableProvided.placeholder}

            {images.map((image, index) => generateImagesDOM(image, index))}

            <FolderNotesWrapperCss>
              <EditTextAreaField
                defaultText={" "}
                style={{ width: "100%" }}
                addIcon={true}
              />
            </FolderNotesWrapperCss>
          </Grid>
        )}
      </Droppable>
    </DragDropContext>
  );
};
