import { GridFolderImages } from "components/GridFolderImages";

// styled-components
import { GridFolderItemListCss } from "./GridFolderItemList.styled";

export const GridFolderItemList = ({
  images,
  indexBasis,
  onUpdate,
}) => {
  return (
    <GridFolderItemListCss>
      <GridFolderImages
        images={images}
        indexBasis={indexBasis}
        onUpdate={onUpdate}
      />
    </GridFolderItemListCss>
  );
};
