// outer sources
import { useState, useEffect } from "react";

// mui
import { Tooltip, IconButton } from "@mui/material";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";

const POSITION_CLASSES = [
  "img-number-right",
  "img-number-left",
  "img-number-center",
];

export const ImgNumberPosition = () => {
  // eslint-disable-next-line no-unused-vars
  const [position, setPosition] = useState("img-number-left");

  useEffect(() => {
    document.querySelectorAll(".img-number").forEach((element) => {
      element.classList.remove(...POSITION_CLASSES);
      element.classList.add(`img-number-${position}`);
    });
  }, [position]);

  return (
    <>
      <Tooltip title="Align left image numbers" placement="top">
        <IconButton onClick={() => setPosition("left")}>
          <FormatAlignLeftIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Align center image numbers" placement="top">
        <IconButton onClick={() => setPosition("center")}>
          <FormatAlignCenterIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Align right image numbers" placement="top">
        <IconButton onClick={() => setPosition("right")}>
          <FormatAlignRightIcon />
        </IconButton>
      </Tooltip>
    </>
  );
};
