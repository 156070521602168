import styled from "@emotion/styled";
import { Box, ButtonGroup, Typography } from "@mui/material";

export const PhotoCropWrapperCss = styled(Box)``;

export const InnerModalBoxCss = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 700px;
  height: 700px;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  background-color: #1e1e1e;
  overflow: hidden;
`;

export const CropContainerCss = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 320px;
  background-color: grey;
`;

export const ControlsContainerCss = styled(Box)`
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 10px;
  bottom: 250px;
  left: 50%;
  width: 90%;
  transform: translateX(-50%);
  height: 40px;
  display: flex;
  align-items: center;
  background-color: light-grey;
`;

export const SliderBoxCss = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 20px;
`;

export const SliderTitleCss = styled(Typography)`
  display: block;
  margin-right: 30px;
  min-width: 80px;
  font-family: inherit;
  font-weight: semi-bold;
  color: white;
`;

export const ButtonBoxCss = styled(Box)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 30px;
`;

export const AspectTemplatesBtnGroup = styled(ButtonGroup)``;
