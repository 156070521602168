// components
import { PrintPanel } from "components/PrintPanel/PrintPanel";
import { NavPanel } from "components/NavPanel/NavPanel";
import { LayoutDesignPanel } from "components/LayoutDesignPanel/LayoutDesignPanel";

// styled-components
import {
  ControlBoardListCss,
  ControlBoardListItemCss,
} from "./ControlBoard.styled";

export const ControlBoard = ({ folders, onUpdate }) => {
  return (
    <ControlBoardListCss className="controlBoard">
      <ControlBoardListItemCss>
        <PrintPanel />
      </ControlBoardListItemCss>

      <ControlBoardListItemCss>
        <NavPanel folders={folders} onUpdate={onUpdate} />
      </ControlBoardListItemCss>

      <ControlBoardListItemCss>
        <LayoutDesignPanel />
      </ControlBoardListItemCss>
    </ControlBoardListCss>
  );
};
