import { EditText } from "react-edit-text";

// styled-components
import { EditTextWrapperCss, EditNoteIconCss } from "./EditTextField.styled";

export const EditTextField = ({ defaultText, style, addIcon }) => {
  const generateIconForEditTextArea = () => {
    return <EditNoteIconCss className={"EditNoteIconCss"} color="primary" />;
  };

  return (
    <EditTextWrapperCss>
      {addIcon ? generateIconForEditTextArea() : null}
      <EditText defaultValue={defaultText} style={style} />
    </EditTextWrapperCss>
  );
};
