// The function returns the directory name from the full file path
export const getDirectoryName = (path) => {
  const pathWithoutFileName = path.slice(0, path.lastIndexOf("/"));

  const directoryName = pathWithoutFileName.slice(
    pathWithoutFileName.lastIndexOf("/") + 1,
    pathWithoutFileName.length
  );

  return directoryName.toUpperCase();
};
