import { useState, useEffect } from "react";
import { getProjectTree } from "../../services/apiService";
import { RichTreeView } from "@mui/x-tree-view";
import { useNavigate } from "react-router-dom";

const buildTree = (data) => {
  const tree = [];
  const lookup = {};

  data.forEach((item) => {
    lookup[item.id] = { ...item, children: [] };
  });
  data.forEach((item) => {
    if (item.parent !== null) {
      lookup[item.parent].children.push(lookup[item.id]);
    } else {
      tree.push(lookup[item.id]);
    }
  });

  return tree;
};

export const ProjectList = ({ selectedProjectId }) => {
  const [tree, setTree] = useState([]);
  const navigate = useNavigate();

  const handleProjectSelection = (event, itemId) => {
    let id = itemId.replace("tree-", "");
    navigate(`/projects/${id}`);
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getProjectTree();
        setTree(buildTree(response));
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [selectedProjectId]);

  return (
    <RichTreeView
      items={tree}
      getItemLabel={(item) => item.name}
      getItemId={(item) => `tree-${item.id}`}
      onItemClick={handleProjectSelection}
    />
  );
};
