// outer sources
import { useState } from "react";

// components
import { TocList } from "components/TocList/TocList";

// mui
import { Tooltip, IconButton } from "@mui/material";
import MenuBookIcon from "@mui/icons-material/MenuBook";

// styled components
import {
  WrapperTocSectionCss,
  WrapperTocSectionTitleCss,
  DrawerCss,
  TitleCss,
} from "./TocSection.styled";

export const TocSection = ({ folders, onUpdate }) => {
  const [isActive, setIsActive] = useState(false);

  const toggleDrawer = () => () => {
    setIsActive(!isActive);
  };

  const handleDrawerClose = (data) => {
    setIsActive(data);
  };

  return (
    <WrapperTocSectionCss>
      <Tooltip title="Show Table of contents" placement="top">
        <IconButton onClick={toggleDrawer()}>
          <MenuBookIcon />
        </IconButton>
      </Tooltip>

      {isActive ? (
        <>
          <DrawerCss
            open={isActive}
            onClose={toggleDrawer()}
            className={"do-not-print-class"}
          >
            <WrapperTocSectionTitleCss>
              <MenuBookIcon color="primary" />
              <TitleCss>TABLE OF CONTENTS</TitleCss>
            </WrapperTocSectionTitleCss>

            <TocList
              folders={folders}
              onUpdate={onUpdate}
              handleDrawerClose={handleDrawerClose}
            />
          </DrawerCss>
        </>
      ) : null}
    </WrapperTocSectionCss>
  );
};
