// outer sources
import { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import ScrollToTop from "react-scroll-to-top";

// components
import { EditTextField } from "components/EditTextField";
import { GridFolders } from "components/GridFolders";
import { ControlBoard } from "components/ControlBoard";

// mui icons
import VerticalAlignTopIcon from "@mui/icons-material/VerticalAlignTop";

export const GridList = ({ externalFiles }) => {
  const [files, setFiles] = useState([]);
  const [toggleChange, setToggleChange] = useState(false);

  useEffect(() => {
    if (externalFiles !== undefined) {
      setFiles(prepareFolders(externalFiles));
    }
  }, [externalFiles]);

  /**
   * Set 3rd parameter as indexBasis for all folders.
   *
   * @param {*} folders
   * @returns
   */
  const prepareFolders = (folders) => {
    let previousImagesCount = 0;
    for (let i = 0; i < folders.length; i++) {
      Object.defineProperty(folders[i], "previousImagesCount", {
        value: previousImagesCount,
        writable: true,
      });

      previousImagesCount += folders[i].folder_files.length;
    }

    return folders;
  };

  /**
   * Unified method to set new values / update existing values
   *
   * @param {*} data
   */
  const onUpdate = (data) => {
    if (!isEmpty(data)) {
      setFiles(prepareFolders(data));
      // useState does not recognize changes in objects. This is a quick solution to re-render
      setToggleChange(!toggleChange);
    }
  };

  return (
    <section className="container">
      {files.length > 0 ? (
        <ControlBoard folders={files} onUpdate={onUpdate} />
      ) : null}

      {files.length > 0 ? (
        <EditTextField
          defaultText={"Додаток до Звіту. Фотозвіт оцінюваного майна"}
          addIcon={false}
          style={{
            width: "100%",
            marginBottom: "30px",
            fontSize: "20px",
            fontWeight: "bold",
          }}
        />
      ) : null}

      <GridFolders folders={files} onUpdate={onUpdate} />

      <ScrollToTop
        styles={{ display: "none" }}
        smooth
        top={"1000"}
        component={<VerticalAlignTopIcon fontSize="large" color="primary" />}
      />
    </section>
  );
};
