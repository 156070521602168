// outer sources
import { useState } from "react";
import { ConfirmProvider } from "material-ui-confirm";

// components
import { PhotoEditor } from "components/PhotoEditor";
import { PhotoCrop } from "components/PhotoCrop";
import { DeleteButtonDialog } from "components/DeleteButtonDialog";

// styled-components
import {
  WrapperAccordionCss,
  AccordionCss,
  AccordionSummaryCss,
  AccordionDetailsCss,
  SettingsIconCss,
} from "./ImageSettingsPanel.styled";

export const ImageSettingsPanel = ({ image, images, id, onUpdate }) => {
  const [openAccordion, setOpenAccordion] = useState(false);

  const handleOnClick = () => {
    !openAccordion || null || undefined
      ? setOpenAccordion(true)
      : setOpenAccordion(false);
  };

  return (
    <WrapperAccordionCss className="image-settings">
      <AccordionCss>
        <AccordionSummaryCss
          expandIcon={<SettingsIconCss fontSize="medium" />}
          aria-controls="image-settings"
          id="accordion-image-settings-header"
          onClick={handleOnClick}
        />

        {openAccordion ? (
          <>
            <AccordionDetailsCss>
              <PhotoEditor image={image} images={images} onUpdate={onUpdate} />
            </AccordionDetailsCss>

            <AccordionDetailsCss>
              <PhotoCrop image={image} images={images} onUpdate={onUpdate} />
            </AccordionDetailsCss>

            <AccordionDetailsCss>
              <ConfirmProvider>
                <DeleteButtonDialog
                  images={images}
                  id={id}
                  onRemove={onUpdate}
                />
              </ConfirmProvider>
            </AccordionDetailsCss>
          </>
        ) : null}
      </AccordionCss>
    </WrapperAccordionCss>
  );
};
