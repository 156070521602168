import { useDropzone } from "react-dropzone";
import ReactGA from "react-ga4";
import { toast } from "react-toastify";
import { toastOptions } from "services/toast-options";

// styled-components
import { DropzoneBoxCss } from "./Dropzone.styled";

// mui icons
import FolderOpenIcon from "@mui/icons-material/FolderOpen";

// utils
import { getFilesDataForStorage } from "../../utils/getFilesDataForStorage";

export const Dropzone = ({ onUpload }) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    onDrop: async (acceptedFiles) => {
      ReactGA.event({ category: "User", action: "Upload Files" });
      let files = getFilesDataForStorage(acceptedFiles);
      onUpload(files);

      setTimeout(() => {
        toast.success(
          files.length > 1
            ? `${files.length} folders added`
            : `${files.length} folder added`,
          toastOptions
        );
      }, 100);
    },
  });

  return (
    <DropzoneBoxCss {...getRootProps({ className: "dropzone" })}>
      <input {...getInputProps()} />
      <p>Drag 'n' drop photo archive folder</p>
      <FolderOpenIcon sx={{ marginLeft: "10px" }} />
    </DropzoneBoxCss>
  );
};
