// outer sources
import Grid from "@mui/material/Grid2";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";

// components
import { EditTextAreaField } from "components/EditTextAreaField";
import { GridFolderItemList } from "components/GridFolderItemList";

// utils
import { reorderArray } from "utils/reorderArray";

// styled-components
import {
  GridFoldersCss,
  GridFolderItemCss,
  GridFolderNameBoxCss,
} from "./GridFolders.styled";

export const GridFolders = ({ folders, onUpdate }) => {
  const handleDragEnd = ({ source, destination }, _provided) => {
    if (!destination) {
      return;
    }
    onUpdate(reorderArray(folders, source.index, destination.index));
  };

  return (
    <GridFoldersCss>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppable">
          {(droppableProvided, snapshot) => (
            <div
              {...droppableProvided.droppableProps}
              ref={droppableProvided.innerRef}
              style={{
                backgroundColor: snapshot.isDraggingOver
                  ? "lightGrey"
                  : "white",
              }}
            >
              {droppableProvided.placeholder}
              {folders.map((folder, index) => {
                const folderName = folder.name;
                const images = folder.folder_files;
                const indexBasis = folder.previousImagesCount;

                return (
                  <Draggable
                    key={folder.id}
                    draggableId={folder.id}
                    index={index}
                  >
                    {(draggableProvided) => (
                      <Grid
                        ref={draggableProvided.innerRef}
                        size={"auto"}
                        {...draggableProvided.draggableProps}
                        {...draggableProvided.dragHandleProps}
                      >
                        <GridFolderItemCss id={`${index + 1}`}>
                          <GridFolderNameBoxCss>
                            <EditTextAreaField
                              defaultText={folderName}
                              style={{ width: "100%", textAlign: "center" }}
                              addIcon={false}
                            />
                          </GridFolderNameBoxCss>

                          <GridFolderItemList
                            images={images}
                            indexBasis={indexBasis}
                            onUpdate={(newImages) => {
                                folders[index].folder_files = newImages;
                                onUpdate(folders);
                              }
                            }
                          />
                        </GridFolderItemCss>
                      </Grid>
                    )}
                  </Draggable>
                );
              })}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </GridFoldersCss>
  );
};
