import styled from "@emotion/styled";
import { Box, Drawer } from "@mui/material";

export const WrapperTocSectionCss = styled(Box)``;

export const WrapperTocSectionTitleCss = styled(Box)`
  display: flex;
`;

export const TitleCss = styled.h2`
  margin-left: 10px;
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 18px;
`;

export const DrawerCss = styled(Drawer)`
  & > .MuiPaper-root {
    padding: 30px 10px;
  }
`;
