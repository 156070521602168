import {
  Box,
  Button,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import {
  Add,
  CloudUpload,
  CloudOff,
  CloudSync,
  CloudDone,
} from "@mui/icons-material";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { uploadFile } from "services/apiService";

const FileSelectionDropzone = ({ onSelectFiles }) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "application/zip": [".zip"],
      "application/x-7z-compressed": [".7z"],
    },
    onDrop: onSelectFiles,
  });

  return (
    <Box {...getRootProps()} sx={{ maxWidth: 100 }}>
      <input {...getInputProps()} />
      <Button>
        <Add />
        Select
      </Button>
    </Box>
  );
};

const UploadStatusMessage = ({ message }) => {
  return message && <Typography sx={{ fontSize: 10 }}>{message}</Typography>;
};

export const UploadFiles = ({ projectId }) => {
  const [message, setMessage] = useState([]);
  const [fileInfo, setFileInfo] = useState([]);

  const onSelectFiles = (files) => {
    files.map((file) => {
      file.percentage = 0;
      file.message = "";
      return file;
    });
    setMessage([]);
    setFileInfo(files);
  };

  const scheduleUpload = () => {
    fileInfo.forEach((file, index) => {
      upload(index, file);
    });
  };

  const upload = (index, file) => {
    uploadFile(projectId, file, (event) => {
      let _fileInfo = [...fileInfo];
      _fileInfo[index].percentage = Math.round(
        (100 * event.loaded) / event.total
      );
      if (_fileInfo[index].percentage === 100) {
        _fileInfo[index].message = "Processing file...";
      }
      setFileInfo(_fileInfo);
    })
      .then((response) => {
        setMessage([
          ...message,
          "File was uploaded successfully: " + file.name,
        ]);
        let _fileInfo = [...fileInfo];
        _fileInfo[index].completed = true;
        _fileInfo[index].message = "File was uploaded successfully";
        setFileInfo(_fileInfo);
      })
      .catch(() => {
        let _fileInfo = [...fileInfo];
        _fileInfo[index].percentage = 0;
        _fileInfo[index].message = "Could not upload the file";
        setMessage([...message, "Could not upload the file: " + file.name]);
        setFileInfo(_fileInfo);
      });
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex">
        <FileSelectionDropzone onSelectFiles={onSelectFiles} />
        {fileInfo.length > 0 ? (
          <Button onClick={scheduleUpload}>
            <CloudUpload /> Upload
          </Button>
        ) : (
          ""
        )}
      </Box>
      <Box display="flex" flexDirection="column">
        <List sx={{ maxWidth: 600 }}>
          {fileInfo &&
            fileInfo.map((file, index) => (
              <ListItem key={`upload-${index}`}>
                <ListItemText sx={{ maxWidth: 200 }}>{file.name}</ListItemText>
                <ListItemText>
                  <LinearProgress
                    key={`progress-${index}`}
                    variant="determinate"
                    value={file.percentage}
                  />
                  <UploadStatusMessage message={file.message} />
                </ListItemText>
                <ListItemIcon sx={{ maxWidth: 46, marginLeft: 1 }}>
                  {file.percentage === 0 ? <CloudOff /> : ""}
                  {file.percentage > 0 && file.percentage < 100 ? (
                    <CloudSync />
                  ) : (
                    ""
                  )}
                  {file.percentage === 100 && file.completed ? (
                    <CloudDone />
                  ) : (
                    ""
                  )}
                </ListItemIcon>
              </ListItem>
            ))}
        </List>
      </Box>
    </Box>
  );
};
