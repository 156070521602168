import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const GridFoldersCss = styled(Box)`
  width: 970px;
  height: auto;
  margin: 0 auto;
`;

export const GridFolderItemCss = styled(Box)`
  position: relative;
`;

export const GridFolderNameBoxCss = styled(Box)`
  position: absolute;
  top: -3px;
  background-color: white;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  z-index: 1;
`;
