import { Helmet, HelmetProvider } from "react-helmet-async";
import { isEmpty } from "lodash";

// components
import { ProjectList } from "../../components/ProjectList";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Drawer,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { UploadFiles } from "components/UploadFiles/UploadFiles";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  createProject,
  getProject,
  getProjectFiles,
} from "services/apiService";
import { GridList } from "components/GridList";
import { Add } from "@mui/icons-material";
import { toast } from "react-toastify";
import { toastOptions } from "../../services/toast-options";

const CreateProjectDialog = ({ open, handleClose }) => {
  const navigate = useNavigate();
  return (
    <Dialog
      open={open}
      closeAfterTransition={false}
      PaperProps={{
        component: "form",
        onSubmit: async (event) => {
          event.preventDefault();
          const formData = new FormData(event.currentTarget);
          const formJson = Object.fromEntries(formData.entries());
          try {
            const project = await createProject(formJson);
            if (!isEmpty(project)) {
              navigate(`/projects/${project.id}`);
            }
          } catch (error) {
            toast.error("Cannot create project", toastOptions);
          }
          handleClose();
        },
      }}
    >
      <DialogTitle>Create Project</DialogTitle>
      <DialogContent>
        <TextField
          required
          autoComplete="off"
          margin="dense"
          id="name"
          name="name"
          label="Project Name"
          fullWidth
          variant="standard"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()}>Close</Button>
        <Button type="submit">Create</Button>
      </DialogActions>
    </Dialog>
  );
};

export const Projects = () => {
  const drawerWidth = 350;
  const { projectId } = useParams();
  const [project, setProject] = useState({});
  const [files, setFiles] = useState([]);
  const [openForm, setOpenForm] = useState(false);

  useEffect(() => {
    async function fetchData(id) {
      try {
        let project = await getProject(id);
        let projectFiles = await getProjectFiles(id);
        setProject(project);
        setFiles(projectFiles);
      } catch (error) {
        console.log(error);
      }
    }
    if (projectId !== undefined) {
      fetchData(projectId);
    }
  }, [projectId]);

  return (
    <HelmetProvider>
      <Helmet>
        <title>Appraisal Photo Report | Projects</title>
      </Helmet>

      <Box display="flex">
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        >
          <Drawer
            variant="permanent"
            sx={{
              display: "block",
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            <Stack direction="row" alignContent="center">
              <Typography variant="h4">Projects</Typography>
              <Button onClick={() => setOpenForm(true)}>
                <Add />
              </Button>
              <CreateProjectDialog
                open={openForm}
                handleClose={(projectId) => setOpenForm(false)}
              />
            </Stack>
            <Divider />
            <ProjectList selectedProjectId={projectId} />
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
          }}
        >
          {project && <Typography variant="h4">{project.name}</Typography>}
          {projectId && <UploadFiles projectId={projectId} />}
          {projectId && files && <GridList externalFiles={files} />}
        </Box>
      </Box>
    </HelmetProvider>
  );
};
