import { Helmet, HelmetProvider } from "react-helmet-async";

// components
import { GridList } from "../../components/GridList";
import { useState } from "react";
import { Dropzone } from "components/Dropzone";

export const Home = () => {
  const [files, setFiles] = useState([]);
  return (
    <HelmetProvider>
      <Helmet>
        <title>Appraisal Photo Report | Home</title>
      </Helmet>
      <section className="container">
        {!files.length > 0 ? <Dropzone onUpload={setFiles} /> : null}
        <GridList externalFiles={files} />
      </section>
    </HelmetProvider>
  );
};
