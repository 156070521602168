// outer sources
import { useState, useEffect } from "react";

// mui
import { Tooltip, IconButton } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

export const ImgNumberShow = () => {
  const [showImgNumber, setShowImgNumber] = useState(true);

  useEffect(() => {
    document.querySelectorAll(".img-number").forEach((element) => {
      showImgNumber ? element.classList.remove("img-number-hide") : element.classList.add("img-number-hide");
    });
  }, [showImgNumber]);

  const handleVisibilityChange = (visibility) => {
    setShowImgNumber(visibility);
  };

  return (
    <>
      {showImgNumber ? (
        <Tooltip title="Hide image numbers" placement="top">
          <IconButton onClick={() => handleVisibilityChange(false)}>
            <VisibilityOffIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Show image numbers" placement="top">
          <IconButton onClick={() => handleVisibilityChange(true)}>
            <VisibilityIcon />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
};
