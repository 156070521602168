import { ButtonCss } from "./Button.styled";

export const Button = ({
  type,
  name,
  variant,
  startIcon,
  disabled,
  onClick,
  color,
  value,
  size,
}) => {
  return (
    <ButtonCss
      type={type}
      variant={variant}
      startIcon={startIcon}
      disabled={disabled}
      onClick={onClick}
      color={color}
      value={value}
      size={size}
    >
      {name}
    </ButtonCss>
  );
};
