import axios from "axios";

const headers = () => {
  return {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
};

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

/**
 * Login user with username and password and get access token in response
 *
 * @param {string} email
 * @param {string} password
 * @returns Object
 */
export const login = async (email, password) => {
  const response = await api.post("/auth/login", {
    email: email,
    password: password,
  });
  return response.data;
};

/**
 * Register user using invitation token and get access token in response
 *
 * @param {string} name
 * @param {string} password
 * @returns Object
 */
export const register = async (name, password) => {
  const response = await api.post("/auth/register", {
    name: name,
    password: password,
    token: localStorage.getItem("invitation_token"),
  });

  return response.data;
};

export const validate = async () => {
  if (localStorage.getItem("token") !== null) {
    await api.get("/token/validate", { headers: headers() });
  }
};

/**
 * Accept organization invitation
 *
 * @returns Object
 */
export const acceptInvite = async () => {
  const response = await api.post(
    "/organizations/invite/accept",
    {
      token: localStorage.getItem("invitation_token"),
    },
    { headers: headers() }
  );

  return response.data;
};

export const getProjectTree = async () => {
  const response = await api.get(`/organizations/projects`, {
    headers: headers(),
  });
  return response.data.rows;
};

export const createProject = async (data) => {
  const response = await api.post(`/organizations/projects`, data, {
    headers: headers(),
  });
  return response.data;
};

export const uploadFile = (projectId, file, onUploadProgress) => {
  let formData = new FormData();
  formData.append("file", file);

  return api.put(`/organizations/projects/${projectId}`, formData, {
    headers: { ...headers(), ...{ "Content-Type": "multipart/form-data" } },
    onUploadProgress,
  });
};

export const getProjectFiles = async (projectId) => {
  const response = await api.get(
    `/organizations/projects/${projectId}/folders/tree`,
    { headers: headers() }
  );
  return response.data;
};

export const getProject = async (projectId) => {
  const response = await api.get(`/organizations/projects/${projectId}`, {
    headers: headers(),
  });
  return response.data;
};
