import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { api } from "../services/apiService";

export const ResponseInterceptor = () => {
  const navigate = useNavigate();
  const interceptorId = useRef(null);

  useEffect(() => {
    interceptorId.current = api.interceptors.response.use(
      undefined,
      (error) => {
        switch (error.response.status) {
          case 401:
            localStorage.removeItem("token");
            navigate("/login");
            break;
          default:
            return Promise.reject(error);
        }
      }
    );
  }, [navigate]);

  return null;
};
