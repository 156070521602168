import { getDirectoryName } from "./getDirectoryName";
import { v4 as uuidv4 } from "uuid";

export const getFilesDataForStorage = (filesData) => {
  let filesDataArray = {};
  // eslint-disable-next-line no-unused-vars
  for (const [key, value] of Object.entries(filesData)) {
    if (filesDataArray[getDirectoryName(value.path)] === undefined) {
      filesDataArray[getDirectoryName(value.path)] = [];
    }
    Object.assign(value, {
      preview: URL.createObjectURL(value),
    });

    filesDataArray[getDirectoryName(value.path)].push(value);
  }

  let folders = [];
  let folderPosition = 0;
  Object.entries(filesDataArray).forEach(([folderName, images]) => {
    let folder = {
      id: uuidv4(),
      name: folderName,
      position: folderPosition++,
      folder_files: [],
    };

    images.forEach((image, index) => {
      Object.assign(image, {
        label: null,
        position: index,
        folderId: folder.id,
        id: uuidv4(),
      });
      folder.folder_files.push(image);
    });

    folders.push(folder);
  });

  return folders;
};
