import { Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const toastOptions = {
  position: "top-center",
  autoClose: 1500,
  pauseOnFocusLoss: false,
  theme: "colored",
  transition: Flip,
};
