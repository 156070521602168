import styled from "@emotion/styled";
import { Stack, Card, CardContent, CardActions } from "@mui/material";

export const AcceptInviteContainerCss = styled(Stack)`
  width: 100%;
  height: 100vh;
  margin-left: auto;
  margin-right: auto;

  background: #8e9eab; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    135deg,
    #8e9eab,
    #eef2f3
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    135deg,
    #8e9eab,
    #eef2f3
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
`;

export const InvitationCardCss = styled(Card)`
  display: flex;
  flex-direction: column;
  min-width: 450px;
  margin: 20vh auto;
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 2px;
`;

export const InvitationCardContentCss = styled(CardContent)`
  max-width: 550px;
  padding: 40px 40px 20px 40px;
`;

export const InvitationCardActionsCss = styled(CardActions)`
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 10px;
  padding: 20px 40px 40px;
`;
