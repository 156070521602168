import { Navigate, Outlet } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { useEffect, useRef } from "react";
import { validate } from "services/apiService";

const ProtectedRoute = (props) => {
  let interval = useRef(null);
  useEffect(() => {
    interval.current = setInterval(validate, 30000);
    return () => clearInterval(interval.current);
  }, []);

  const token = localStorage.getItem("token");

  if (!token) {
    return <Navigate to="/login" />;
  }

  const data = jwtDecode(token);
  let currentTimestamp = new Date().getTime() / 1000;
  if (data.exp < currentTimestamp) {
    return <Navigate to="/login" />;
  }

  return <Outlet {...props} />;
};

export default ProtectedRoute;
